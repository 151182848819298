<template lang="pug">
.om-modal.image-manager(@click="hide")
  .content(@click.stop="")
    .header
      .header-inner
        .om-row
          .om-col.om-col-12.header-content
            .om-title-image {{ $t('imageManager') }}
            .button.om-close(@click="hide") &times;
    .main(
      :class="{ 'no-img': !images.length }"
      @drop.prevent="onUploadDrop"
      @dragover.prevent="onDragOver"
      @dragleave.prevent="onDragLeave"
    )
      .upload-cloud-wrapper(:class="{ 'center-img': images.length > 0 }")
        i.fa.fa-cloud-upload
      .upload-area
        .upload-container
          .upload-cloud-wrapper(:class="{ 'no-img': images.length === 0 }")
            i.fa.fa-cloud-upload
          .button.button-large.upload-button.p-3(@click="openFileChooser").
            {{$t('imageUploadNew')}}
            #[input(type="file" multiple="multiple" accept=".jpeg,.jpg,.jpeg-2000,.png,.svg,.gif,.tiff,.webp,.avif" style="display: none" ref="fileInput" @change="onFileChange")]
          .upload-message.p-2 {{ $t('imageUploadMessage') }}
          .upload-size.upload-maxfile.p-2 {{ $t('imageMaxSize', { size: MAX_KBYTE, sizeMB: MAX_KBYTE / 1024 }) }}
          .image-replace-toggle(v-if="hasEditorBatchImageChange && showReplaceToggle")
            om-switch#replaceImageToggle(
              :label="$t('imageManagerModals.library.toggle.label')"
              v-model.sync="imageReplace"
            )
      .images.scrollable
        .image(v-if="newImageLoading")
          img(:src="require('@/assets/editor/svg/om_picture.svg')")
        .image(v-for="(i, index) in images")
          inline-svg(v-if="i.url.includes('.svg')" :src="i.url")
          om-progressive-image(
            v-else
            :src="`${i.url}?ts=${timestamp(i.updatedAt)}`"
            placeholder="/om_picture.svg"
            backgroundColor="transparent"
          )
          .image-hover
            .image-data
              .image-name {{ i.name }}
              .image-res {{ $t('dimensions') }}: {{ i.width }}x{{ i.height }}
            .card.text-center.m-2(v-if="isBlacklisted(i.url)")
              .card-box.p-1 {{ $t('imageCantUse', { ext: isBlacklisted(i.url) }) }}
            .button.button-primary.button-large.p-3(v-else @click="useImage(index)") {{ $t('imageUse') }}
            .button-group
              .button.button-large
                i.fa.fa-trash.fa-2x(@click="removeImage(index)")
      .upload-drop-message.pointer-events-none(v-show="dragover")
        i.fa.fa-plus.fa-5x
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import { track } from '@/services/xray';
  import { isSvgUrl } from '@/editor/util';
  import { get as _get } from 'lodash-es';
  import { restClient as axios } from '@/editor/axios';
  import { client as apollo, gql } from '@/editor/apollo';
  import itemMixin from '@/editor/mixins/item';
  import imageReplaceMixin from '@/mixins/imageManager/imageReplace';

  const MAX_KBYTE = 4096;

  const frame = () => document.getElementById('workspaceFrame');
  const frameWindow = () => frame().contentWindow;
  const frameStore = () => frameWindow().om.store;

  export default {
    mixins: [itemMixin, imageReplaceMixin],
    data() {
      return {
        MAX_KBYTE,
        dragover: false,
        newImageLoading: false,
        canEditMobile: true,
        canUseNested: true,
        selectedPageEl: null,
      };
    },
    computed: {
      ...mapGetters(['columnInfoOfSelectedElement', 'isNano']),
      ...mapState([
        'imageManagerDestination',
        'images',
        'imageDropColumn',
        'templateSaveData',
        'selectedElement',
        'selectedPath',
        'mobilePreview',
        'selectedPage',
      ]),
      extensionBlacklist() {
        return this.imageManagerDestination.extBlacklist || [];
      },
    },
    watch: {
      selectedPage: {
        handler(v) {
          if (v && v.uid) {
            this.$nextTick(() => {
              this.selectedPageEl = frameWindow().document.getElementById(v.uid);
            });
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.loadImages();
    },
    methods: {
      ...mapMutations(['activatePaneL2', 'hideImageManagerV2']),
      openFileChooser() {
        this.$refs.fileInput.click();
      },
      timestamp(str) {
        return new Date(str).getTime();
      },
      onFileChange(e) {
        this.createImages(e.target.files);
      },
      onDragOver() {
        this.dragover = true;
      },
      onDragLeave() {
        this.dragover = false;
      },
      onUploadDrop(e) {
        this.dragover = false;
        this.createImages(e.dataTransfer.files);
      },
      createImages(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.type.match('^image/')) {
            this.createImage(file);
          } else {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.unsupportedFileFormat'),
            });
          }
        }
      },
      createImage(file) {
        const self = this;
        const reader = new FileReader();
        reader.fileName = file.name;
        this.$bus.$emit('showAdminLoader', true);
        self.newImageLoading = true;
        reader.onload = (e) => {
          const url = e.target.result;
          const htmlImage = new Image();
          htmlImage.src = url;
          htmlImage.onload = function () {
            const kbytes = file.size / 1024;
            track('campaignImageUpload', { kbytes });
            if (kbytes > MAX_KBYTE) {
              const msg = self.$t('fileSizeToLargeMsg', { size: MAX_KBYTE });
              self.$bus.$emit('showAdminLoader', false);
              self.newImageLoading = false;
              // eslint-disable-next-line
              window.alert(msg);
              return;
            }
            const splitName = e.target.fileName.split('.');
            const name = `${splitName[0].substr(0, 17)}_${new Date().getTime()}.${splitName[1]}`;
            const data = new FormData();
            data.append('file', file, name);
            data.append('type', self.templateSaveData.type);
            data.append('value', self.templateSaveData.value);
            data.append('height', this.height);
            data.append('width', this.width);
            axios
              .post('upload/editor', data, { headers: { 'content-type': 'multipart/form-data' } })
              .then(() => {
                self.loadImages();
              })
              .catch((err) => {
                const text =
                  err.response && err.response.data && err.response.data.error
                    ? err.response.data.error
                    : this.$t('notifications.errorWhileUploadingImage');

                self.$notify({
                  type: 'error',
                  text,
                });
              });
          };
        };
        reader.readAsDataURL(file);
      },
      removeImage(index) {
        const image = this.images[index];
        this.$bus.$emit('showAdminLoader', true);
        axios
          .delete(`upload/editor/${image._id}`, {
            params: {
              name: image.name,
              saveType: this.templateSaveData.type,
              saveValue: this.templateSaveData.value,
            },
          })
          .then((result) => {
            const { success, useCount } = result.data;
            if (success) {
              this.loadImages();
            } else {
              // eslint-disable-next-line
              window.alert(`Image is used in ${useCount} campaigns`);
              this.$bus.$emit('showAdminLoader', false);
            }
          });
      },
      async useImage(index) {
        const { _id, url } = this.images[index];
        const selectedImage = { imageId: _id, imageUrl: url };
        const currentImage = { ...this.currentImage };
        if (isSvgUrl(url)) {
          await this.loadSvgInline(this.images[index]);
        }

        const { colorInstance } = this.imageManagerDestination;
        this.$nextTick(() => {
          colorInstance.setImage(_id, url);
          this.$store.commit('handleColorInstanceToStore', colorInstance);
          this.hide();
        });

        colorInstance.setImageCrop(null);
        if (colorInstance.getImagePosition() === 'crop') {
          colorInstance.setImagePosition('cover');
          this.$store.commit('handleColorInstanceToStore', colorInstance);
        }
        this.$bus.$emit('rebuild-color-instance');
        this.$bus.$emit('re-render-all-previews');
        this.showImageReplaceIfNeeded({ currentImage, selectedImage });
      },
      async loadSvgInline(image) {
        const { data: svg } = await axios.get(image.url, { withCredentials: false });

        frameStore().state.template.inlineSvgs = frameStore().state.template.inlineSvgs || {};
        frameStore().state.template.inlineSvgs[image._id] = svg;
        this.$store.state.inlineSvgs = this.$store.state.inlineSvgs || {};
        this.$store.state.inlineSvgs[image._id] = svg;
      },
      loadImages() {
        const { type, value } = this.templateSaveData;
        this.$bus.$emit('showAdminLoader', true);
        const templateQuery = gql`{images: allTemplateImages(template: "${value}"){_id, name, url, height, width}}`;
        const variantQuery = gql`
          {
            images: allImages {
              _id
              name
              url
              height
              width
              updatedAt
            }
          }
        `;
        apollo
          .query({
            query: type === 'base' ? templateQuery : variantQuery,
          })
          .then((r) => {
            const baseImages = frameStore().state.baseImages;
            const baseImageHash = {};
            baseImages.forEach((image) => {
              baseImageHash[image._id] = true;
            });
            const images = _get(r, 'data.images') || [];
            this.$bus.$emit('addImages', [
              ...baseImages,
              ...images.filter((image) => !baseImageHash[image._id]),
            ]);
            this.$nextTick(() => {
              this.$bus.$emit('showAdminLoader', false);
              this.newImageLoading = false;
            });
          });
      },
      isBlacklisted(url) {
        let blacklisted = null;
        this.extensionBlacklist.forEach((ext) => {
          if (url.endsWith(`.${ext}`)) blacklisted = ext;
        });
        return blacklisted;
      },
      hide() {
        const { addElement } = this.imageManagerDestination;

        let imageId = _get(this.selectedElement, 'desktop.background.imageId');

        if (this.mobilePreview) {
          imageId = _get(this.selectedElement, 'mobile.background.imageId');
        }

        if (addElement && !imageId && this.selectedElement) {
          this.$bus.$emit('removeElement', { uid: this.selectedElement.uid });
        }
        this.hideImageManagerV2();
      },
    },
  };
</script>
